import * as React from 'react'
import Template from "../components/template";
import "./projects.css";
import "../components/tags.css";
import RoleIcon from "../components/icons/role";

const Page = () => {
    const projects = [
        {
            name: "6th place",
            platformName: "Sovcombank Challenge 2021",
            platformLink: "https://scbhack.sk.ru/",
            body: <React.Fragment>
                <p>It was a hackathon for Java developers sponsored by Sovcombank and Skolkovo. There were ~600 participants from Russia.</p>
                <p>The first round was a test with 101 questions related to Java and Software Engineering (2 hours).</p>
                <p>In the second round, I was asked to write a web service, implement API, integrate this service with two external services using different protocols (SOAP+REST JSON), and implement logic with correct timeout handling, parallel processing and so on (4 hours).</p>
                <p>The solution is a Docker image that should be pushed to the organizer's repository and automatically tested by their test system for correctness and ability to handle high load.</p>
                <p>I got <a href="https://cups.mail.ru/ru/results/scbhack?period=past&roundId=600" target="_blank" rel="nofollow">1st place</a> in the first round and <a href="https://cups.mail.ru/ru/results/scbhack?period=past&roundId=602" target="_blank" rel="nofollow">6th place</a> in the final.</p>
            </React.Fragment>,
            stack: ["Java", "Hackathon"],
            date: "May 2021",
            role: "Prize-winner"
        },
        {
            name: "1st place",
            platformName: "Grid Dynamics contest (Joker 2018)",
            platformLink: "https://2018.jokerconf.com/en/",
            body: <React.Fragment>
                <p>Got 1st place in the Grid Dynamics Java contest at Joker 2018 conference.</p>
            </React.Fragment>,
            stack: ["Java", "Hackathon"],
            date: "Oct 2018",
            role: "Prize-winner"
        },
        {
            name: "32nd place",
            platformName: "HighLoad Cup 2017",
            platformLink: "https://highloadcup.ru/en/main/",
            body: <React.Fragment>
                <p>My <a href="https://github.com/chemist777/highloadcup2017" target="_blank" rel="nofollow">solution</a> was one of a few Java solutions and used Java+C via JNI.</p>
                <p>I got 9th place in the first round and <a href="https://cups.mail.ru/ru/results/45?period=past&roundId=416" target="_blank" rel="nofollow">32st place</a> in the final.</p>
            </React.Fragment>,
            stack: ["High load", "Java", "Hackathon"],
            date: "Aug 2017",
            role: "Top 50"
        },
        {
            name: "Different places",
            platformName: "Competitions",
            body: <React.Fragment>
                <p>Got different place in different competitions, but don't remember them all :)</p>
            </React.Fragment>,
            stack: ["Hackathon"],
            date: "2000-2017",
            role: "Participant"
        },
        {
            name: "2nd place",
            platformName: "PL: Computers magazine",
            body: <React.Fragment>
                <p>Got 2nd place in the Software Engineers competition organized by the "PL: Computers" magazine.</p>
            </React.Fragment>,
            stack: ["Hackathon"],
            date: "2002",
            role: "Prize-winner"
        },
        {
            name: "First money",
            platformName: "Internet",
            body: <React.Fragment>
                <p>At the age of 17 y.o., developed, advertised, and sold popular applications of my own via the Internet.</p>
            </React.Fragment>,
            stack: ["Software", "Selling"],
            date: "2000",
            role: "Software Engineering"
        },
        {
            name: "1st places",
            platformName: "School olympiads",
            body: <React.Fragment>
                <p>Got first places in Computer science & Chemistry regional school olympiads.</p>
                <p>Got high places in Chemistry all-Russian olympiads.</p>
                <p>Got free education in Moscow Chemical lyceum as a talented student.</p>
                <p>Entered the university based on Math olympiad results.</p>
                <p>Was receiving 2 scholarships as a talented student.</p>
            </React.Fragment>,
            stack: ["School", "Olympiads", "Science", "Scholarship"],
            date: "1998-2000",
            role: "Winner, Scholarships recipient"
        },
        {
            name: "Scholarship",
            platformName: "Painting school",
            body: <React.Fragment>
                <p>Was receiving a scholarship as a talented painter.</p>
            </React.Fragment>,
            stack: ["Painting", "Scholarship"],
            date: "1996-1997",
            role: "Scholarship recipient"
        },
    ]
    const projectsEl = projects.map((p, index) => {
        let company
        if (p.platformLink) {
            company = <a rel="nofollow" target="_blank" href={p.platformLink}>{p.platformName}</a>
        } else if (p.platformName) {
            company = <React.Fragment>{p.platformName}</React.Fragment>
        }
        if (company) company = <React.Fragment> @ {company}</React.Fragment>
        let tags
        if (p.stack) {
            const tagEls = p.stack.map(tag => <span key={tag}>{tag}</span>)
            tags = <div className="tags">
                {tagEls}
            </div>
        }

        let role = "";
        if (p.role) {
            role = <div className="text-sm inline-flex items-center text-gray-500" title="Role"><RoleIcon className="inline w-4 mr-1 fill-current"/>{p.role}</div>
        }
        return <div className="space-y-2" key={"achievement-item-" + index}>
            <div className="flex items-baseline">
                <div className="text-xl flex-grow">
                    <span className="font-bold">{p.name}</span>{company}
                </div>
                <div className="text-gray-400 text-xs sm:text-sm whitespace-nowrap ml-1">{p.date}</div>
            </div>
            {role}
            {p.body}
            {tags}
        </div>
    })
    return (
        <Template page="achievements" title="Achievements">
            <div className="blocks">
                {projectsEl}
            </div>
        </Template>
    )
}
export default Page
