import * as React from 'react'
import {Link} from "gatsby";
import "./template.css";

const Template = ({children, page, wide, title}) => {
    title = "Alexander Kharitonov" + (title ? (" | "+ title) : "");
    // let contentClass = styles.content;
    // if (!wide) contentClass += " max-w-screen-sm";
    let contentWrapperClass = "";
    if (!wide) contentWrapperClass = "mx-auto max-w-screen-sm";
    return (
        <main>
            <title>{title}</title>
            <div className="bg-gray-100 min-h-screen">
                <div className="shadow bg-white">
                    <div className="max-w-screen-sm mx-auto py-2">
                        <div className="flex justify-between">
                            <div className="ml-10 mr-3 flex items-center">
                                <Link to="/" className="sm:text-lg">Alexander Kharitonov</Link>
                            </div>
                            <div className="menu-items">
                                <div>
                                    <Link to="/projects/" className={page === "projects" ? "active" : ""}>
                                        Projects
                                    </Link>
                                    <Link to="/achievements/" className={page === "achievements" ? "active" : ""}>
                                        Achievements
                                    </Link>
                                    <Link to="/media/" className={page === "media" ? "active" : ""}>
                                        Media
                                    </Link>
                                    <Link to="/contact/" className={page === "contact" ? "active" : ""}>
                                        Contact
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={contentWrapperClass}>
                    <div className="content">
                        {children}
                        {/*<div className="text-right">*/}
                        {/*    <div className="btn-group">*/}
                        {/*        <button className="btn btn-xs btn-outline btn-active">EN</button>*/}
                        {/*        <button className="btn btn-xs btn-outline">RU</button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div>&nbsp;</div>
            </div>
        </main>
    )
}
export default Template
